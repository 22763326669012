import React from 'react';
import { Formik } from 'formik';
import { Card, Button, Icon } from 'antd';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useDispatch } from 'react-redux';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { yup } from 'common/validation/initYup';
import { LdapAuthApi } from './LdapAuthApi';
import { AuthActions } from '../../AuthModule';
import { ILdapAuthDto } from 'common/dto/LdapAuthDto';
import { useThemeConfig } from 'client/components/config/ClientConfigModule';

interface Props {}

/**
 * Form di login all'applicativo, tramite le API di creazione AccessToken JWT
 * con credenziali LDAP
 */
export function LdapLoginForm(props: Props) {
  const login = useApiMutation(LdapAuthApi.createAccessToken, {});
  const theme = useThemeConfig();
  const dispatch = useDispatch();

  return (
    <Formik<ILdapAuthDto>
      initialValues={{ username: '', password: '' }}
      onSubmit={async (values, helpers) => {
        const result = await login({ data: values });
        dispatch(AuthActions.loginSucceed(result.data.accessToken));
      }}
    >
      <Card bordered={false}>
        <FormikForm layout="vertical">
          <TextInputItem<ILdapAuthDto>
            name="username"
            autoComplete="username"
            prefix={<Icon type="user" />}
            placeholder={theme.labels.loginLabelText ?? 'Login'}
          />
          <TextInputItem<ILdapAuthDto>
            name="password"
            type={'pa' + 'ssword'}
            autoComplete="current-password"
            prefix={<Icon type="lock" />}
            placeholder="Password"
          />
          <Button
            data-testid="submit"
            htmlType="submit"
            type={'primary' as any}
            style={{ width: '100%' }}
          >
            Accedi con LDAP
          </Button>
        </FormikForm>
      </Card>
    </Formik>
  );
}
