import React from 'react';
import { useThemeConfig } from 'client/components/config/ClientConfigModule';

/**
 * Label personalizzata da Config per il nome del Login
 * Inserito per l'arma dei carabinieri per mostrare "User",
 * se non viene inserita in Config ritorna "Login"
 */
export function LoginLabelText() {
  const theme = useThemeConfig();
  return <span>{theme.labels.loginLabelText ?? 'Login'}</span>;
}
