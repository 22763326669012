import * as React from 'react';
import { FormItem } from 'client/ui/form/FormItem';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import {
  DiscardPolicyRuleOperators,
  DiscardPolicyRuleField,
  DiscardPolicyRuleOperator,
  DiscardPolicyRuleType,
  DiscardPolicyRule
} from 'common/schema/DiscardPolicyRule';
import { useCallback } from 'react';
import { useFormikContext, useField } from 'formik';
import { SelectValue } from 'antd/lib/select';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { PermissionLogic } from 'common/logic/PermissionLogic';
import { Roles } from 'common/permissions/Roles';
import { IUser } from 'common/schema/User';
import { DiscardRuleLogic } from 'common/logic/DiscardRuleLogic';

export interface IDiscardPolicyRuleOperatorInputProps {
  rule: DiscardPolicyRule;
  index: number;
  ruleField: DiscardPolicyRuleField | undefined;
  disabled?: boolean;
}

export function DiscardPolicyRuleOperatorInput(
  props: IDiscardPolicyRuleOperatorInputProps
) {
  const { index, ruleField, disabled } = props;

  const name = `rules.${index}.operator`;
  const valueName = `rules.${index}.value`;

  const user = useCurrentUser();

  const formik = useFormikContext();

  const operators = DiscardRuleLogic.getOperators(ruleField, user);

  // Reset del campo "valore" al cambio dell'operatore
  const handleChange = useCallback(() => {
    formik.setFieldValue(valueName, undefined);
  }, [valueName]);

  return (
    <FormItem name={name} label={index === 0 && 'Operatore'} colon={false}>
      <SelectInput
        name={name}
        options={operators}
        disabled={ruleField == null || disabled}
        onChange={handleChange}
      />
    </FormItem>
  );
}
