import { IUser } from 'common/schema/User';
import { IClientSecurityConfig } from 'common/config/ISecurityConfig';
import { IClientConfig } from 'common/config/IClientConfig';
import { Roles } from 'common/permissions/Roles';

export class UserLogic {
  static canLogout(user: IUser | null) {
    // Fallback per pre-original-strategy
    if (user?.originalStrategy == null && user?.strategy !== 'windows')
      return true;

    if (
      user?.originalStrategy === 'jwt' ||
      user?.originalStrategy === 'openam' ||
      user?.originalStrategy === 'ldap'
    )
      return true;

    return false;
  }

  static isAdmin(user: IUser | null): boolean {
    return !!user?.bindings?.some(binding => binding.role === Roles.Admin.name);
  }
}
