import * as React from 'react';
import { Tabs, Col } from 'antd';
const { TabPane } = Tabs;
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { RouteComponentProps } from 'react-router';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { LabeledText } from 'client/ui/labeled-text/LabeledText';
import { ISODate } from 'client/ui/display/date/ISODate';
import { LabeledTextGroup } from 'client/ui/labeled-text/LabeledTextGroup';
import { GrantApi } from 'client/components/grant/GrantApi';
import { GrantStatusTag } from 'client/components/grant/detail/GrantStatusTag';
import { TableGrantsDossierList } from 'client/components/grant/edit/TableGrantsDossierList';
import { TableGrantsDocumentList } from 'client/components/grant/edit/TableGrantsDocumentList';
import { GrantRequestsTable } from '../relations/GrantRequestsTable';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { GrantLogic } from 'common/logic/grant/GrantLogic';

interface Params {
  id: string;
}

export interface GrantRequestViewPageProps
  extends RouteComponentProps<Params> {}

/**
 * Visualizzazione di un permesso lato consultatore (richiedente)
 */
export function GrantRequestViewPage(props: GrantRequestViewPageProps) {
  const id = parseInt(props.match.params.id, 10);

  const user = useCurrentUser();

  const { response, error, loading } = useApiQuery(GrantApi.findOwned, {
    skip: !user,
    data: { id, userId: user?.id! }
  });

  if (error) return <AlertPage type="error" message={error.message} showIcon />;

  const grant = response?.data;

  // Mostriamo i link solo se il permesso è valido (approvato)
  const hideLink = !GrantLogic.isApproved(grant);

  return (
    <PageLayout
      hasScrollable
      hasMenu
      loading={loading || !grant}
      title={
        <CustomCardTitle
          image="grant"
          titleLabel="Dettaglio Permesso:"
          title={id + (grant?.description ? ' | ' + grant.description : '')}
        />
      }
    >
      <RowScroller>
        <ColScroller span={8} side="left">
          <div className="grant-summary">
            <LabeledTextGroup inline>
              <LabeledText
                label="Stato"
                text={<GrantStatusTag grant={grant} />}
                style={{ minWidth: '150px' }}
              />
              <LabeledText
                label="Del"
                text={<ISODate date={grant?.createdAt} />}
              />
              <LabeledText label="ID" text={id} />
            </LabeledTextGroup>
            <LabeledTextGroup inline>
              <LabeledText
                label="Data inizio"
                text={<ISODate date={grant?.beginsAt} />}
                style={{ minWidth: '150px' }}
              />
              <LabeledText
                label="Valido fino il"
                text={<ISODate date={grant?.expiresAt} />}
              />
            </LabeledTextGroup>
            {/* <LabeledText label="Concesso a" text={grant?.user.name} /> */}
            {/* TODO: Può essere necessario mostrare chi ha concesso il permesso */}
            <LabeledText
              label="Responsabile concedente il permesso"
              text={grant?.author?.name ?? 'N.A.'}
            />
            <LabeledText
              ellipsis={false}
              label="Descrizione"
              text={grant?.description}
            />
            {/* TODO Aggiungere */}
            {/* <LabeledText
              ellipsis={false}
              label="Note"
              text="Note / Descrizione dettagliata della richiesta (se necessario)"
            /> */}
          </div>
        </ColScroller>
        <Col span={16}>
          <Tabs type="card" className="tabs-content-scrollable">
            {/* Elenco dei fascicoli */}
            <TabPane
              className="spacing-tablescroll full-table"
              key="dossiers"
              tab="Elenco Fascicoli"
            >
              <TableGrantsDossierList
                dossiers={grant?.dossiers ?? []}
                hideLink={hideLink}
                bottomSpace={58}
              />
            </TabPane>
            {/* Elenco dei documenti */}
            <TabPane
              className="spacing-tablescroll full-table"
              key="document"
              tab="Elenco Documenti"
            >
              <TableGrantsDocumentList
                documents={grant?.documents ?? []}
                hideLink={hideLink}
                bottomSpace={58}
              />
            </TabPane>
            {/* Dettaglio Richiesta */}
            <TabPane
              className="spacing-tablescroll full-table"
              key="request"
              tab="Dettaglio Richiesta"
            >
              <GrantRequestsTable
                requests={grant?.requests ?? []}
                bottomSpace={58}
              />
            </TabPane>
          </Tabs>
        </Col>
      </RowScroller>
    </PageLayout>
  );
}
