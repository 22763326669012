import React, { useState } from 'react';
import clx from 'classnames';
import { Row, Button, Col, Tabs, Icon, Timeline, message } from 'antd';
const { TabPane } = Tabs;
import { PageLayout } from 'client/ui/layout/PageLayout';
import { CustomCardTitle } from 'client/ui/layout/page-card/CustomCardTitle';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CustomCardExtra } from 'client/ui/layout/page-card/CustomCardExtra';
import { LabeledText } from 'client/ui/labeled-text/LabeledText';
import { LabeledTextGroup } from 'client/ui/labeled-text/LabeledTextGroup';
import { Scrollable } from 'client/ui/scroller/Scrollable';
import { ColScroller } from 'client/ui/scroller/ColScroller';
import { RowScroller } from 'client/ui/scroller/RowScroller';
import { StatisticSpaceUsedItem } from 'client/ui/statistic/StatisticSpaceUsedItem';
import './DiscardEditPage.scss';
import { DiscardStatusTag } from '../detail/status/DiscardStatusTag';
import { FormikForm } from 'client/ui/form/FormikForm';
import { Formik } from 'formik';
import { DiscardPreserveModal } from './DiscardPreserveModal';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DiscardApi } from '../DiscardApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ISODate } from 'client/ui/display/date/ISODate';
import { resolveApiPath } from 'client/core/appConfig';
import { DiscardSelectionRules } from '../detail/selection-rules/DiscardSelectionRules';
import { TimelineCustomItem } from 'client/ui/timeline/TimelineCustomItem';
import { useInterval } from 'client/core/hooks/useInterval';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { DiscardDossiersTable } from './relations/DiscardDossiersTable';
import { DiscardLogic } from 'common/logic/DiscardLogic';
import { DiscardRemoveButton } from '../detail/remove-button/DiscardRemoveButton';
import { DiscardDocumentsTable } from './relations/DiscardDocumentsTable';
import { getDiscardCountReport } from '../detail/count-report/getDiscardCountReport';
import { DiscardStartButton } from '../detail/start-button/DiscardStartButton';
import { PageCard } from 'client/ui/layout/PageCard';
import { DiscardAttachmentUploadInput } from './DiscardAttachmentUploadInput';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import { CcTooltip } from 'client/ui/tooltip/CcTooltip';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { updateDiscardDto } from 'common/dto/DiscardDto';
import { DiscardRetryButton } from '../detail/retry-button/DiscardRetryButton';

interface Params {
  id: string;
}

export interface DiscardEditPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di visualizzazione della Singola policy di scarto.
 */
export function DiscardEditPage(props: DiscardEditPageProps) {
  // test Pagina in modalità Modifica
  const [isEditing, setIsEditing] = useState(false);
  const onSave = () => {
    setIsEditing(false);
  };

  const id = parseInt(props.match.params.id, 10);

  const { response, error, loading, refetch } = useApiQuery(DiscardApi.find, {
    data: { id: id! }
  });
  const discard = response?.data.discard;

  // Aggiorno, se è in corso, la pagina
  useInterval(
    () => {
      refetch();
    },
    DiscardLogic.shouldRefresh(discard) && !isEditing ? 15_000 : null
  );

  // Modifica
  const isEditable = DiscardLogic.isEditableOrRemovable(discard);
  const update = useApiMutation(DiscardApi.update, {
    invalidates: [DiscardApi.find]
  });

  // Errore
  if (error) {
    return (
      <AlertPage
        message="Impossibile caricare lo Scarto"
        description={getNetworkErrorMessage(error)}
      />
    );
  }

  // Caricamento
  if (!discard) return <LoadingPage />;

  return (
    <PageLayout hasScrollable hasMenu excludeCard className="discard-edit-page">
      <Formik
        initialValues={discard}
        enableReinitialize
        validate={schemaToValidator(updateDiscardDto.validator)}
        onSubmit={async (rawValues, helpers) => {
          const values = await updateDiscardDto.validator.validate(rawValues, {
            abortEarly: false
          });

          try {
            await update({ data: { id: discard.id, input: values } });
            message.success('Scarto aggiornato con successo. ');
            setIsEditing(false);
          } catch (e) {
            console.error('errors', e);
            message.error(
              "Si è verificato un errore durante l'aggiornamento dello scarto."
            );
          }
        }}
      >
        {formik => (
          <PageCard
            hasScrollable
            loading={loading && isEditable}
            title={
              <CustomCardTitle
                image="discard"
                titleLabel="Scarto:"
                title={discard.description}
              />
            }
            extra={
              <CustomCardExtra
                right={
                  isEditing ? (
                    <>
                      <CcTooltip title="Annulla Modifiche">
                        <Button
                          icon="close-circle"
                          onClick={() => {
                            setIsEditing(false);
                            formik.resetForm();
                          }}
                        />
                      </CcTooltip>
                      <Button
                        type="primary"
                        htmlType="submit"
                        onClick={formik.submitForm}
                      >
                        Salva Modifiche
                      </Button>
                      {/* <DiscardPreserveModal
                        onModalClose={onSave}
                        table={<TableScroll rowSelection={{}} />}
                      /> */}
                    </>
                  ) : (
                    <>
                      {isEditable && (
                        <>
                          <Button onClick={() => setIsEditing(true)}>
                            Modifica Scarto
                          </Button>
                        </>
                      )}
                      <DiscardRemoveButton discard={discard} />
                      <DiscardStartButton discard={discard} />
                      <DiscardRetryButton discard={discard} />
                    </>
                  )
                }
              />
            }
          >
            <RowScroller>
              <ColScroller span={8} side="left" spacing="inside">
                <FormikForm layout="vertical">
                  {/* Dati Generali */}
                  <div className="discard-policy-summary">
                    <LabeledText
                      label="Stato"
                      text={<DiscardStatusTag discard={discard} />}
                      style={{ width: '100%' }}
                    />
                    <LabeledTextGroup inline>
                      <LabeledText label="ID" text={discard.id.toString()} />
                      <LabeledText
                        label="Data"
                        text={<ISODate date={discard.createdAt} />}
                      />
                      <LabeledText
                        label="Creato da"
                        text={discard.author?.name ?? 'N.A.'}
                      />
                    </LabeledTextGroup>
                    {isEditing && (
                      <TextInputItem label="Descrizione" name="description" />
                    )}
                  </div>
                  <Row gutter={12}>
                    {getDiscardCountReport(discard, response!.data).map(
                      (data, i) => (
                        <StatisticSpaceUsedItem
                          data={data}
                          key={i}
                          type="compact"
                          dataLabel="Scartati"
                          column={2}
                          totalProgress={DiscardLogic.totalProgress(
                            discard,
                            response!.data.meta
                          )}
                        />
                      )
                    )}
                  </Row>
                  <div className="discard-policy-summary">
                    <LabeledText
                      label="Rapporto di Scarto"
                      text={
                        <a
                          target="_blank"
                          href={resolveApiPath(
                            `/discards/${discard.id}/report/Report_di_Scarto.xml`
                          )}
                        >
                          <Icon type="file" /> Rapporto_di_Scarto.xml
                        </a>
                      }
                    />
                    <LabeledText
                      label="Pdf Rapporto di Scarto"
                      text={
                        <a
                          target="_blank"
                          href={resolveApiPath(
                            `/discards/${discard.id}/pdf-report/Report_di_Scarto.pdf`
                          )}
                        >
                          <Icon type="file-pdf" /> Rapporto_di_Scarto.pdf
                        </a>
                      }
                    />
                    {isEditing ? (
                      <DiscardAttachmentUploadInput discard={discard} />
                    ) : (
                      discard.attachmentPath && (
                        <LabeledText
                          label="Allegato allo Scarto"
                          text={
                            <a
                              target="_blank"
                              href={resolveApiPath(
                                `/discards/${discard.id}/attachment`
                              )}
                            >
                              <Icon type="file" /> {discard.attachmentFileName}
                            </a>
                          }
                        />
                      )
                    )}
                  </div>
                </FormikForm>
              </ColScroller>

              <ColScroller span={16} forceHeight hasTableScroll>
                <Tabs type="card" className="tabs-content-scrollable">
                  {/* Elenco dei fascicoli */}
                  <TabPane
                    className="spacing-tablescroll full-table"
                    key="dossiers"
                    tab="Elenco Fascicoli"
                  >
                    <DiscardDossiersTable
                      discard={discard}
                      isEditable={isEditing}
                    />
                  </TabPane>

                  {/* Elenco dei documenti */}
                  <TabPane
                    className="spacing-tablescroll full-table"
                    key="documents"
                    tab="Elenco Documenti"
                  >
                    <DiscardDocumentsTable
                      discard={discard}
                      isEditable={isEditing}
                    />
                  </TabPane>

                  {/* Cronologia degli scarti. Ordine anti-cronologico history-container */}
                  <TabPane key="history" tab="Cronologia">
                    <Scrollable spacing="inside">
                      <Timeline>
                        {(discard.history ?? []).map(history => (
                          <TimelineCustomItem
                            id={history.id}
                            type={history.type}
                            createdAt={history.createdAt}
                            author={history.author}
                            description={history.description}
                            mode="row"
                          />
                        ))}
                      </Timeline>
                    </Scrollable>
                  </TabPane>

                  {/* Policy di scarto */}
                  <TabPane key="policy" tab="Policy di Scarto">
                    <Scrollable spacing="inside">
                      <div className="discard-policy-summary">
                        <LabeledText
                          label="Nome della Policy di Scarto"
                          text={discard.policy.name}
                          ellipsis={false}
                        />
                        <LabeledText
                          label="Descrizione"
                          text={discard.policy.description ?? ''}
                          ellipsis={false}
                        />
                        {/* Regole di Selezione */}
                        <LabeledText
                          label="Regole di Selezione"
                          text={
                            <DiscardSelectionRules policy={discard.policy} />
                          }
                        />
                      </div>
                    </Scrollable>
                  </TabPane>
                </Tabs>
              </ColScroller>
              {/* </ColScroller> */}
            </RowScroller>
          </PageCard>
        )}
      </Formik>
    </PageLayout>
  );
}
