import React from 'react';
import { LoginLayout } from './LoginLayout';
import { LoginForm } from './LoginForm';
import { RouteComponentProps, Redirect } from 'react-router';
import { useAuth } from '../AuthModule';
import {
  useClientConfig,
  useAvailableAuthStrategies
} from 'client/components/config/ClientConfigModule';
import { LdapLoginForm } from '../strategies/ldap/LdapLoginForm';

interface Props extends RouteComponentProps {}

/**
 * Disponibile soltanto se l'utente non ha ancora effettuato il login.
 * In caso di successo, lo reindirizza alla pagina corretta.
 */
export const LoginRoute = (props: Props) => {
  const { logged } = useAuth();
  const availableStrategies = useAvailableAuthStrategies();

  // Calcolo l'origine
  const { from } = (props.location.state as any) || { from: { pathname: '/' } };

  if (logged) return <Redirect to={from} />;

  console.log('availableStrategies', availableStrategies);
  const Form = availableStrategies?.includes('ldap')
    ? LdapLoginForm
    : LoginForm;

  return (
    <LoginLayout>
      <Form />
    </LoginLayout>
  );
};
